body {
  background-color: var(--atmospheric-white, whitesmoke);
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  font-size: 62.5%;
}

/* NOTE media queries currently use placeholder values */
/* TODO update media query widths & font-size %'s after UX decisions */
@media all and (max-width: 1536px) {
  html {
    font-size: 50%;
  }
}

@media all and (max-width: 1200px) {
  html {
    font-size: 40%;
  }
}

@media all and (max-width: 900px) {
  html {
    font-size: 30%;
  }
}

@media all and (max-width: 600px) {
  html {
    font-size: 20%;
  }
}

/*.App {*/
/*  position: fixed;*/
/*  width: 100%;*/
/*  height: 100%;*/
/*}*/
